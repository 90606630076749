let sliderClass = "j-cards--slider";

/**
 * initializeBlock
 *
 * All JS to load on the block page goes here.
 *
 * @date    15/4/19
 * @since   1.0.0
 *
 * @param   object $block The block jQuery element.
 * @param   object attributes The block attributes (only available when editing).
 * @return  void
 */
var initializeBlock = function($block, $attributes) {
	let elem = $block;
	if (!$block.hasClass(sliderClass)) {
		elem = $block.find("." + sliderClass);
	}
	elem.each(function() {
		let columns = $(this).data("columns");
		let style = $(this).data("style");

		let args = {
			dots: true,
			arrows: true,
			adaptiveHeight: true,
			slidesToShow: columns,
			slidesToScroll: 1,
			infinite: false,
			appendDots: $(this)
				.parent()
				.find(".cp-cards__slider__dots"),
			appendArrows: $(this)
				.parent()
				.find(".cp-cards__slider__nav"),
			nextArrow: $(this)
				.parent()
				.find(".cp-cards__slider__nav__arrow--next"),
			prevArrow: $(this)
				.parent()
				.find(".cp-cards__slider__nav__arrow--prev")
		};
		if (style != "case-studies") {
			args.responsive = [
				{
					breakpoint: 1300,
					settings: {
						slidesToShow: Math.min(columns, 3)
					}
				},
				{
					breakpoint: 900,
					settings: {
						slidesToShow: 2
					}
				},
				{
					breakpoint: 800,
					settings: {
						slidesToShow: 1
					}
				}
			];
		} else {
			args.variableWidth = true;
			args.responsive = [
				{
					breakpoint: 844,
					settings: {
						slidesToShow: 1,
						variableWidth: false
					}
				}
			];
		}
		$(this).slick(args);
	});
};

// Initialize each block on page load (front end).
$(document).ready(function() {
	$("." + sliderClass).each(function() {
		initializeBlock($(this), false);
	});
});

// Initialize dynamic block preview (editor).
if (window.acf) {
	window.acf.addAction("render_block_preview/type=cards", initializeBlock);
}
